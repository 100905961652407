<div class="font-14 brand-profile-color-all d-flex " (scroll)="scrollHandler()" #stylesnapShotFeeds>
    <div class="scroll-band" [ngClass]="this.funcValue != 'loaded' ? '' : 'new-scroll-class'">
        <div class="fixed-container">
            <div class="justify-content-center text-center  brand-profile-sy p-1"
                [ngClass]="!this.closeDiv ? 'pointer-name' : ''">
                <div class="col-lg-12 garmentName" *ngIf="!showBrandFlag">
                    <div class="justify-content-center text-center d-flex set-margin">
                        <h6 class="text-primary brandbtn m-1 py-2 d-block color-class" (click)="setTab('story','')">
                            <div *ngIf="tab == 'story'"
                                [ngClass]="tab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                                <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/storyblack.png">
                            </div>
                            <div *ngIf="tab == 'news-stories'"
                                [ngClass]="tab == 'news-stories' ? 'story-menu-card-gray' : 'story-menu-icon'">
                                <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/story-white.png">
                            </div>
                            <img *ngIf="tab != 'story' && tab != 'news-stories' "
                                [ngClass]="tab != 'story' && tab != 'news-stories' ? 'story-menu-icon' : ''"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/story-white.png">
                            <h6 *ngIf="tab == 'story' || tab == 'news-stories' " class="color-class-sy m-1  story_name">
                                Stories</h6>
                        </h6>
                        <h6 class="text-primary brandbtn m-1 py-2 d-block color-class"
                            [ngClass]="this.loadingState   ? 'loadingState-disabled' : ''">
                            <img *ngIf="tab == 'mens-wear'"
                                [ngClass]="tab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/MenIconBlack.png">
                            <img (click)="setTab('mens-wear','M')" *ngIf="tab != 'mens-wear'"
                                [ngClass]="tab != 'mens-wear' ? 'story-menu-icon' : ''"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/MenIconWhite.png">
                            <h6 *ngIf="tab == 'mens-wear'" class="color-class-sy m-1">Menswear</h6>
                        </h6>
                        <h6 class="text-primary brandbtn m-1 py-2 d-block color-class"
                            [ngClass]="this.loadingState   ? 'loadingState-disabled' : ''">
                            <img *ngIf="tab == 'womens-wear'"
                                [ngClass]="tab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconBlack.png">
                            <img (click)="setTab('womens-wear','F')" *ngIf="tab != 'womens-wear'"
                                [ngClass]="tab != 'womens-wear' ? 'story-menu-icon' : ''"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconWhite.png">
                            <h6 *ngIf="tab == 'womens-wear'" class="color-class-sy m-1 w-margin">Womenswear</h6>
                        </h6>
                    </div>
                </div>
                <div id="mySidenav" class="sidenav" [style.height.px]="sideNavHeight">
                    <div class="d-flex justify-content-center" *ngIf="isloadingBrandPanel">
                        <div class="spinner-border is-loading m-3  " role="status">
                            <span class=""></span>
                        </div>
                    </div>


                  

                    <div  [ngClass]="this.newWidth == '50px'   ? 'd-none': ''" #storyContainer 
                        *ngIf="!showBrandFlag  && !isloadingBrandPanel &&  (tab =='story' || tab =='news-stories') "
                        class="my-story" style="overflow: auto;">
                        <div *ngIf="tab == 'story' && userSnapShotList?.length == 0" class="danger">
                            <div class="alert alert-danger" role="alert">
                                "Stories will be available soon"
                            </div>
                        </div>
                        <div *ngIf="tab == 'story' && userSnapShotList?.length == 0" class="text-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>

                        <div class="row m-1 p-1 justify-content-center" [style.height.px]="brandStoryScrollerHeight"
                            *ngIf="userSnapShotList?.length >= 0  && userSnapShotList != undefined && userSnapShotList?.length >= 0">

                            <h6 *ngIf="pageiIndex != undefined" (click)="previousBrandbutton()">
                                <b *ngIf="callpageNumer">Previous</b>
                            </h6>

                            <div [ngClass]="{'d-none': snapshot.SnapshotFileUrl == ''}" class="column d-block"
                                *ngFor="let snapshot of userSnapShotList; let i=index;">
                                <!-- Only render the entire block if SnapshotFileUrl exists -->
                                <div *ngIf="snapshot.SnapshotFileUrl != ''">
                                    <img [ngClass]="{'story_image_selection': i == selectedImageIndex}"
                                        class="columnImg d-block shadow-1-strong rounded mb-1"
                                        (click)="selectImage(i, snapshot.StoryID)" [src]="snapshot.SnapshotFileUrlMini">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div [ngClass]="this.newWidth == '50px'   ? 'd-none': ''" *ngIf="!showBrandFlag  &&  (tab =='story' || tab =='news-stories') "
                        class="col-12 text-center text-white stify-content-center">
                        <h6 (click)="nextBrandButton('c')">
                            <b>More</b>
                        </h6>
                    </div>
                    <div  class="collaps-menu"    [ngClass]="this.newWidth == '50px'   ? 'd-none': ''">
                        <div class="contain-fluid"
                            [ngClass]="brandObject != undefined && (tab =='womens-wear' || tab =='mens-wear') ? '' : 'd-none'">
                        </div>
                        <div class="col-lg-12 p-1">
                            <span
                                *ngIf="errMessage != '' && tab !='story' &&  tab !='news-stories' && this.brandId != undefined "
                                class="danger">
                                <div class="alert alert-danger" role="alert">
                                    {{errMessage}}
                                </div>
                            </span>
                            <div *ngIf="isLoadingStyle" class="d-flex justify-content-center">
                                <div class="spinner-border text-white" role="status">
                                    <span class=""></span>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="!flipFlag">
                            <div *ngIf="tab =='womens-wear' &&  isLoadingStyle == false " class="d-flex my"
                                [ngClass]="this.loadingState   ? 'no-touch' : ''">
                                <div class=" file womenswear_scroller ">
                                    <h6 class="text-white">{{tempMessage}}</h6>
                                    <div class="file" *ngIf="!isLoading ">
                                        <div class="row form "
                                            *ngIf="packJsonObject?.length > 0 && packJsonObject[0]?.value != undefined && sceneintilizedDone">
                                            <div #scrollContainerWHeadGear class="slider-css-head"
                                                (scroll)="scrollAndUnZipFileIntilize('headgear')">
                                                <div *ngFor="let object of packJsonObject[0]?.value.files;let i=index"
                                                    id="elementwomens-wearheadgear{{i}}" class="slider-img2-upper">
                                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                        <div class="spinner-border spn-load text-white" role="status">
                                                        </div>
                                                    </div>
                                                    <div class="head-div">
                                                        <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                            (click)="DeatailsPopup(zipList[object],'headgear', object,'HeadwearID',brandFlag )" />
                                                    </div>
                                                    <img (click)="open3Dflag(zipList[object],'headgear',object ,'HeadwearID')"
                                                        class="profileInfo  upperwear_info col-sm-2"
                                                        style="width:25px; height:25px !important;"
                                                        src="/assets/realtype/planinfoicon.png">
                                                </div>
                                            </div>
                                            <div #scrollContainerWUpper class="slider-css2-upper"
                                                (scroll)="scrollAndUnZipFileIntilize('upperwear')">
                                                <div *ngFor="let object of packJsonObject[1]?.value.files;let i=index"
                                                    id="elementwomens-wearupperwear{{i}}" class="slider-img2-upper">
                                                    <div class="upper-div">
                                                        <div class="d-flex justify-content-center"
                                                            *ngIf="!zipList[object]">
                                                            <div class="spinner-border spn-load text-white"
                                                                role="status">
                                                            </div>
                                                        </div>
                                                        <img *ngIf="zipList[object]" class="img-responsive-upper"
                                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                            (click)="DeatailsPopup(zipList[object],'upperwear',object,'UpperwearID',brandFlag)" />
                                                        <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                                            class="profileInfo   col-sm-2"
                                                            style="width:25px; height: 25px !important;"
                                                            src="./assets/realtype/planinfoicon.png">
                                                    </div>
                                                </div>
                                            </div>
                                            <div #scrollContainerWBottom class="slider-css2-upper"
                                                (scroll)="scrollAndUnZipFileIntilize('bottomwear')">
                                                <div *ngFor="let object of packJsonObject[2]?.value.files;let i=index"
                                                    id="elementwomens-wearbottomwear{{i}}" class="slider-img2-upper">
                                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                        <div class="spinner-border spn-load text-white" role="status">
                                                        </div>
                                                    </div>
                                                    <div class="bottom-div">
                                                        <img class="img-responsive-bottom" *ngIf="zipList[object]"
                                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                            (click)="DeatailsPopup(zipList[object],'bottomwear', object,'BottomwearID',brandFlag )" />
                                                    </div>
                                                    <img (click)="open3Dflag(zipList[object],'bottomwear',object ,'BottomwearID')"
                                                        class="profileInfo  upperwear_info col-sm-2"
                                                        style="width:25px; height:25px !important;"
                                                        src="/assets/realtype/planinfoicon.png">
                                                </div>
                                            </div>
                                            <div #scrollContainerWFootwear class="slider-css2-upper"
                                                (scroll)="scrollAndUnZipFileIntilize('footwear')">
                                                <div *ngFor="let object of packJsonObject[3]?.value.files;let i=index"
                                                    id="elementwomens-wearfootwear{{i}}" class="slider-img2-upper">
                                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                        <div class="spinner-border spn-load text-white" role="status">
                                                        </div>
                                                    </div>
                                                    <div class="foot-div">
                                                        <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                            (click)="DeatailsPopup(zipList[object],'footwear', object,'FootwearID',brandFlag )" />
                                                    </div>
                                                    <img (click)="open3Dflag(zipList[object],'footwear',object ,'FootwearID')"
                                                        class="profileInfo  upperwear_info col-sm-2"
                                                        style="width:25px; height:25px !important;"
                                                        src="/assets/realtype/planinfoicon.png">
                                                </div>
                                            </div>

                                            <div *ngIf="isLoading" class="d-flex justify-content-center">
                                                <div class="spinner-border" role="status">
                                                    <span class=""></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="tab =='mens-wear' &&  isLoadingStyle == false " class="d-flex my"
                                [ngClass]="this.loadingState   ? 'no-touch' : ''">

                                <div class=" file womenswear_scroller ">
                                    <h6 class="text-white">{{tempMessage}}</h6>
                                    <div class="file" *ngIf="!isLoading ">
                                        <div class="row form d-block "
                                            *ngIf="packJsonObject != undefined && sceneintilizedDone">
                                            <div #scrollContainerMHeadGear class="slider-css-head"
                                                (scroll)="scrollAndUnZipFileIntilize('headgear')">
                                                <div *ngFor="let object of packJsonObject[0]?.value.files;let i=index"
                                                    id="elementmens-wearheadgear{{i}}" class="slider-img2-upper">
                                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                        <div class="spinner-border spn-load text-white" role="status">
                                                        </div>
                                                    </div>
                                                    <div class="head-div">
                                                        <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                            (click)="DeatailsPopup(zipList[object],'headgear', object,'HeadwearID',brandFlag )" />
                                                    </div>
                                                    <img (click)="open3Dflag(zipList[object],'headgear',object ,'HeadwearID')"
                                                        class="profileInfo  upperwear_info col-sm-2"
                                                        style="width:25px; height:25px !important;"
                                                        src="/assets/realtype/planinfoicon.png">
                                                </div>
                                            </div>


                                            <div #scrollContainerMUpper class="slider-css2-upper"
                                                (scroll)="scrollAndUnZipFileIntilize('upperwear')">
                                                <div *ngFor="let object of packJsonObject[1]?.value.files;let i=index"
                                                    id="elementmens-wearupperwear{{i}}" class="slider-img2-upper">
                                                    <div class="upper-div">
                                                        <div class="d-flex justify-content-center"
                                                            *ngIf="!zipList[object]">
                                                            <div class="spinner-border spn-load text-white"
                                                                role="status">
                                                            </div>
                                                        </div>
                                                        <img *ngIf="zipList[object]" class="img-responsive-upper"
                                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                            (click)="DeatailsPopup(zipList[object],'upperwear',object,'UpperwearID',brandFlag)" />
                                                        <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                                            class="profileInfo   col-sm-2"
                                                            style="width:25px; height:25px !important;"
                                                            src="./assets/realtype/planinfoicon.png">
                                                    </div>
                                                </div>
                                            </div>
                                            <div #scrollContainerMBottom class="slider-css2-upper"
                                                (scroll)="scrollAndUnZipFileIntilize('bottomwear')">
                                                <div *ngFor="let object of packJsonObject[2]?.value.files;let i=index"
                                                    id="elementmens-wearbottomwear{{i}}" class="slider-img2-upper">
                                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                        <div class="spinner-border spn-load text-white" role="status">
                                                        </div>
                                                    </div>
                                                    <div class="bottom-div">
                                                        <img class="img-responsive-bottom" *ngIf="zipList[object]"
                                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                            (click)="DeatailsPopup(zipList[object],'bottomwear', object,'BottomwearID',brandFlag )" />
                                                    </div>
                                                    <img (click)="open3Dflag(zipList[object],'bottomwear',object ,'BottomwearID')"
                                                        class="profileInfo  upperwear_info col-sm-2"
                                                        style="width:25px; height:25px !important;"
                                                        src="/assets/realtype/planinfoicon.png">
                                                </div>
                                            </div>
                                            <div #scrollContainerMFootwear class="slider-css2-upper"
                                                (scroll)="scrollAndUnZipFileIntilize('footwear')">
                                                <div *ngFor="let object of packJsonObject[3]?.value.files;let i=index"
                                                    id="elementmens-wearfootwear{{i}}" class="slider-img2-upper">
                                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                        <div class="spinner-border spn-load text-white" role="status">
                                                        </div>
                                                    </div>
                                                    <div class="foot-div">
                                                        <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                            (click)="DeatailsPopup(zipList[object],'footwear', object,'FootwearID',brandFlag )" />
                                                    </div>
                                                    <img (click)="open3Dflag(zipList[object],'footwear',object ,'FootwearID')"
                                                        class="profileInfo  upperwear_info col-sm-2"
                                                        style="width:25px; height:25px !important;"
                                                        src="/assets/realtype/planinfoicon.png">
                                                </div>
                                            </div>
                                            <div *ngIf="isLoading" class="d-flex justify-content-center">
                                                <div class="spinner-border" role="status">
                                                    <span class=""></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="flipFlag" class="flipFlag-class" id="scrollableDiv" #setclothBand
                            [style.height.px]="sideNavHeight" (scroll)="onGarmentScroll()">

                            <div (click)="flipClose()" class="text-white text-end class-close-btn">X
                            </div>
                            <div class="set-main-div">
                                <div class="set-img-div" *ngIf="!swichImageFlage">
                                    <img *ngIf="garmentClothType != 'bottomwear'" (click)="setCloth()"
                                        class="img-responsive-flip-class" [src]="source" />
                                    <img *ngIf="garmentClothType == 'bottomwear'" (click)="setCloth()"
                                        class="img-responsive-flip-class-bottom" [src]="source" />
                                </div>
                                <div *ngIf="swichImageFlage">
                                    <div class="" id="target" *ngIf="packJsonObject.length > 0 ">
                                        <app-twod-image [garmentImg]="this.source"
                                            (triggedCloth)="setSelectedGarmetCloth($event)"
                                            [garmentType]="this.garmentClothType" [pageType]="style"></app-twod-image>
                                    </div>
                                    <div class="d-flex justify-content-center" *ngIf="isloading">
                                        <div class="spinner-border" role="status">
                                            <span class=""></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="setcloth-band">
                                <div class="mb-2 text-black clothName color-class-band">
                                    <div class="d-flex garment-title">
                                        <div class="text-center d-inline-flex garmen-name-in-band">
                                            <div class="">
                                                <span *ngIf="clothDetails?.RealType == '0'" class="brandbtn py-1"><img
                                                        style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                                        src="./assets/realtype/headgear.png"></span>
                                                <span *ngIf="clothDetails?.RealType == '1'" class="brandbtn py-1 "><img
                                                        style="width:18px !important; color:black ;padding-bottom: 12px; opacity: 0.6; 
                                                        margin-right: 4px;"
                                                        src="./assets/realtype/upperwear.png"></span>
                                                <span *ngIf="clothDetails?.RealType == '2'" class="brandbtn py-1"><img
                                                        style="width:18px !important; color:black ;padding-bottom: 5px; opacity: 0.6;"
                                                        src="./assets/realtype/bottomwear.png"></span>
                                                <span *ngIf="clothDetails?.RealType == '3'" class="brandbtn py-1"><img
                                                        style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                                        src="./assets/realtype/footwear.png"></span>
                                                <span *ngIf="clothDetails?.RealType == '4'" class="brandbtn py-1"><img
                                                        style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                                        src="./assets/realtype/heeledfootwear.png"></span>
                                                <span *ngIf="clothDetails?.RealType == '5'" class="brandbtn py-1"><img
                                                        style="width:18px !important; color:black ;padding-bottom: 10px; opacity: 0.6;"
                                                        src="./assets/realtype/fullbody.png"></span>
                                            </div>
                                            <h6 class="brand-font class-band-name" style="wrap: break-word;">
                                                {{(clothDetails?.Name).split()}}
                                            </h6>
                                        </div>
                                        <div>
                                            <div class="text-center band-3d-icon d-flex col-sm-1">
                                                <h6 class=" text-primary  color-class">
                                                    <img *ngIf="!garmentScrollFlag" (click)="switch3DImage()"
                                                        class="threeD_icon " src="./assets/realtype/3d-paranoma.png">
                                                    <img *ngIf="garmentScrollFlag" (click)="flipClose()"
                                                        class="threeD_icon " src="./assets/realtype/paraCloseBlack.png">
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                        <h6 (click)="selectIcon('popup')"
                                            class="text-primary brandbtn m-1 d-block class">
                                            <div *ngIf="selectedIcon == 'popup'"
                                                [ngClass]="selectedIcon == 'popup'    ? 'story-menu-card-band'  : 'story-menu-icon'">
                                                <img style="width: 12px;" src="./assets/storystyle/story-white.png">
                                            </div>
                                            <img *ngIf="selectedIcon != 'popup'" class="story-menu-icon"
                                                style="color:black ;margin-top: 0px; width: 10px;"
                                                src="./assets/storystyle/storyblack.png">

                                            <h6 *ngIf="selectedIcon == 'popup'" class="selected-name p-1">Story</h6>
                                        </h6>
                                        <h6 (click)="selectIcon('detail')"
                                            *ngIf="clothDetails?.ThumbCenterURL  != undefined" class="m-1 brandbtn">
                                            <div *ngIf="selectedIcon == 'detail'"
                                                [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                                                <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank">
                                                    <img style="width: 12px;;" src="./assets/details.png"></a>
                                            </div>
                                            <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank"><img
                                                    *ngIf="selectedIcon != 'detail'"
                                                    [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''"
                                                    style="color:black ;"
                                                    src="./assets/storystyle/newStoryIcon/detailGray.png"></a>

                                            <h6 *ngIf="selectedIcon == 'detail'" class="selected-name p-1">Details</h6>
                                        </h6>
                                        <h6 *ngIf="clothDetails?.ThumbCenterURL  == undefined" class="m-1 brandbtn">
                                            <a><img style="width:15px !important; color:black"
                                                    src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                                        </h6>
                                        <h6 (click)="selectIcon('gallary')"
                                            *ngIf="clothDetails?.ThumbLeftURL  != undefined" class="m-1 brandbtn">
                                            <div *ngIf="selectedIcon == 'gallary'"
                                                [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                                                <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank">
                                                    <img style="width: 12px; " src="./assets/gallary.png"></a>
                                            </div>
                                            <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank"><img
                                                    *ngIf="selectedIcon != 'gallary'"
                                                    [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''"
                                                    style="color:black;"
                                                    src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                                            <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name p-1">Gallary</h6>
                                        </h6>
                                        <h6 *ngIf="clothDetails?.ThumbLeftURL  == undefined" class="m-1 brandbtn">
                                            <a><img style="width:15px !important ;"
                                                    src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                                        </h6>
                                        <h6 (click)="selectIcon('cart')"
                                            *ngIf="clothDetails?.ThumbRightURL  != undefined" class="m-1 brandbtn">
                                            <div *ngIf="selectedIcon == 'cart'"
                                                [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                                                <a href="{{clothDetails?.ThumbRightURL}}" target="_blank">
                                                    <img style="width: 12px; " src="./assets/shop.png"></a>
                                            </div>
                                            <a href="{{clothDetails?.ThumbRightURL}}" target="_blank"><img
                                                    *ngIf="selectedIcon != 'cart'"
                                                    [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                                    style="color:black; width: 1px;"
                                                    src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                                            <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping</h6>
                                        </h6>
                                        <h6 *ngIf="clothDetails?.ThumbRightURL  == undefined" class="m-1 brandbtn">
                                            <a><img style="width:15px !important; color:black"
                                                    src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="img-detail-story">
                                <div class="d-flex justify-content-center" *ngIf="isloadingPanel">
                                    <div class="spinner-border is-loading m-3  " role="status">
                                        <span class=""></span>
                                    </div>
                                </div>
                                <div class="twoDImage d-block mb-2" *ngIf="!isloadingPanel">
                                    <div class="" *ngIf="userFileList != undefined">
                                        <app-image-slide [images]="userFileList"></app-image-slide>
                                    </div>

                                    <div class="d-flex" *ngIf="userFileList == undefined || userFileList.length == 0">
                                        <app-image-slide [images]="images"></app-image-slide>
                                    </div>

                                    <div *ngIf="userFiledetails3d != undefined"
                                        class="text-center d-flex upload-image-slider-css-both twoDImage-both">
                                        <div *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                                            <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" camera-controls
                                                camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                                                alt="A 3D avatar-model-view" data-js-focus-visible=""
                                                ar-status="not-presenting"
                                                style="height: 80px; width: 100px; float: none;">
                                            </model-viewer>
                                        </div>
                                    </div>


                                </div>
                                <div
                                    [ngClass]="userFiledetails3d.length != 0   ? 'details collection-details-sku p-2' : 'collection-details-sku3d details'">
                                    <h6 class=" color-class "
                                        *ngIf="clothDetails?.CollectionName != undefined && clothDetails?.CollectionName != '' ">
                                        Collection: {{filterCollectionName(clothDetails .CollectionName)}}</h6>
                                    <h6 class=" color-class "
                                        *ngIf="clothDetails?.CollectionName == '' ||  clothDetails?.CollectionName == null">
                                        Collection: None</h6>

                                    <h6 class=" color-class"
                                        *ngIf="clothDetails?.Details != undefined &&  userFiledetails3d.length != 0">
                                        {{(showMore) ? clothDetails .Details : clothDetails .Details | slice:0:120}}
                                        <span *ngIf="!showMore  &&  userFiledetails3d.length != 0 ">...</span>
                                        <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length != 0"
                                            (click)="showMore=true">Read More</a>
                                        <a href="javascript:;" *ngIf="showMore &&  userFiledetails3d.length != 0"
                                            (click)="showMore=false">Read less</a>
                                    </h6>

                                    <h6 class="color-class"
                                        *ngIf="clothDetails?.Details != undefined  &&  userFiledetails3d.length == 0 ">
                                        {{(showMore) ? clothDetails.Details : clothDetails .Details| slice:0:400}}
                                        <span *ngIf="!showMore && clothDetails.Details.length < 400">...</span>
                                        <a href="javascript:;"
                                            *ngIf="!showMore && clothDetails.Details.length > 400  &&  userFiledetails3d.length == 0"
                                            (click)="showMore=true">Read More</a>
                                        <a href="javascript:;" *ngIf="showMore  &&  userFiledetails3d.length == 0 "
                                            (click)="showMore=false">Read less</a>
                                    </h6>
                                    <h6 class="color-class"
                                        *ngIf="clothDetails?.Details == undefined  &&  userFiledetails3d.length < 0 ">
                                        {{(showMore) ? dummyText : dummyText | slice:0:150}} <span
                                            *ngIf="!showMore">...</span>
                                        <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length < 0 "
                                            (click)="showMore=true">Read More</a>
                                        <a href="javascript:;" *ngIf="showMore  && userFiledetails3d.length < 0 "
                                            (click)="showMore=false">Read less</a>
                                    </h6>
                                    <h6 class="color-class"
                                        *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length == 0">

                                        Your brand's apparel is digitised in 3D
                                        <br><br>
                                        On a gamified experience, consumers style avatars & share animated avatar
                                        stories
                                        (linked to ecommerce)
                                        <br><br>
                                        Defining a new paradigm in immersive storytelling for social media & the
                                        metaverse
                                    </h6>
                                    <h6 class="color-class"
                                        *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length > 0  && userFiledetails.length > 0">
                                        {{(showMore) ? dummyText : dummyText}} "
                                        <a href="javascript:;" *ngIf="!showMore &&  
                                clothDetails .Details == undefined &&  
                                userFiledetails3d.length > 0  && userFiledetails.length > 0 "
                                            (click)="showMore=true">Read More</a>
                                        <a href="javascript:;"
                                            *ngIf="showMore  &&  userFiledetails3d.length > 0  && userFiledetails.length > 0"
                                            (click)="showMore=false">Read less</a>
                                    </h6>

                                    <h6 class="color-class"
                                        *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0">
                                        {{(showMore) ? dummyText : dummyText}}<a href="javascript:;"
                                            *ngIf="!showMore && clothDetails?.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0"
                                            (click)="showMore=true">Read More</a>
                                        <a href="javascript:;" *ngIf="showMore " (click)="showMore=false">Read less</a>
                                    </h6>
                                    <h6 class="color-class">SKU: {{clothDetails?.SKU}}</h6>
                                </div>
                                <div #storyContainer class="mb-5  my-storyzz" *ngIf="!isloadingPanel">
                                    <div class=" row m-1 p-1 justify-content-center "
                                        *ngIf="userSnapShotListGarment?.length >= 0  && userSnapShotListGarment != undefined && userSnapShotListGarment?.length >= 0">

                                        <h6 *ngIf="pageiIndex" (click)="previousbutton()"><b
                                                *ngIf="callpageNumer > 0 ">Previous</b></h6>
                                        <div class="column d-block"
                                            *ngFor="let snapshot of userSnapShotListGarment; let i=index;">
                                            <img *ngIf="snapshot.SnapshotFileUrl != ''"
                                                [ngClass]="{'story_image_selection': i == selectedImageIndex}"
                                                class="columnImg d-block  shadow-1-strong rounded mb-1 "
                                                (click)="selectGarmentImage(i,snapshot.StoryID)"
                                                [src]="snapshot.SnapshotFileUrlMini">
                                        </div>

                                        <div class="col-12 text-center text-white stify-content-center">
                                            <h6 *ngIf="this.userSnapShotListGarment?.length >= 12 "
                                                (click)="nextButton('c')">
                                                <b>More</b>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center" *ngIf="isloadingPanel">
                                    <div class="spinner-border is-loading m-3 " role="status">
                                        <span class=""></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slider slide-fatness" *ngIf="tab !='news-stories' &&  tab !='story' && BrandSelection">
                    <label for="customRange1" class="form-label">Size range</label>
                    <input type="range" class="form-range" id="customRange1" [(ngModel)]="fatnessValue"
                        (input)="onFatnessChange()">
                    <label for="customRange1" class="">{{fatnessValue}}</label>
                </div>
            </div>
        </div>

       

        <div class="select_brand">
            <div class="select-brand-class " *ngIf="byScroll &&  portFolioFlag " (click)="handleContainerClick($event)">
                <div class="col-lg-12 justify-content-center  info-tag  please_select_brand  ">

                    <div class="contain-fluid m-1">
                        <div class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                            <div class="m-0 col-8">
                                <div class="col-12 container vertical-center-band">
                                    <h5 class="p-2 text-white p-n-f">Please Select Brand.</h5>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showBrandFlag != true">
                            <button (click)="closeButtonForBrand()"
                                class="yologram-btn-close rounded-pill text-white">close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!showOverlay" class="overlay-tab">

            <!-- <div class="menu-arrow text-end">
                <button (click)="openNav()"  class="yologram-btn rounded-pill text-white"></button>
            </div> -->

            <div class="menu-arrow text-end tab-box" (click)="openNav()">
                <img style="width:20px;" src="./assets/search/brand.png" alt="Open Avatar">
            </div>
            <div class="menu-arrow text-end tab-box" style="margin-top: 55px;"   (click)="showTempSearchPanel()">
                <img style="width:20px;" src="./assets/brand-space/search-button.png" alt="Open Search">
            </div>
            
        </div>
    </div>
    <div>
        <!-- [ngClass]="BrandSelection && (tab !='news-stories' || tab !='story') &&  (tab =='mens-wear' || tab =='womens-wear') ? 'top-class' : 'hidden-class'"> -->
        <div class="class-dive top-class">
            <div class="spinner-container" [ngClass]="this.loadingState   ? '' : 'd-none'">
                <div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div (click)="closeNav()">
                <div *ngIf="!isLoadEnv" class="blur-container"></div>
                <div *ngIf="!isLoadEnv" class="d-flex justify-content-center evn-loader">
                    <div class="spinner-border" role="status">
                        <span class=""></span>
                    </div>
                </div>
                <app-news3-d-popup (lodingStateFlag)="setPageloading($event)" (triggedUrl)="setSelectedUrl($event)"
                    (sceneDone)="setSceneFlag($event)" (errorMessage)="setEroorMessage($event)" [EnvUrl]="evnDefault"
                    (calledFunction)="functionLoaded($event)" [AvatarUrl]="this.avtarUrl" [headgearURL]="headgear"
                    [upperwearURL]="upperwear" [bottomwearURL]="bottomwear" [footwearURL]="footwear"
                    [GarmentsGender]="this.selectBrandGender" [InputFatness]="fatnessValue" [BrandIdName]="brandId"
                    [FunctionFlag]="this.functionFlag" [queryHeadgear]="queryHeadgear" [queryUpperwear]="queryUpperwear"
                    [queryBottomwear]="queryBottomwear" [queryFootwear]="queryFootwear" [StoryID]="storyId"
                    [DefaultAvatar_M]="this.defaultAvatar_M" [DefaultAvatar_F]="this.defaultAvatar_F" [BlobUrl]="blob"
                    (skyboxLoaded)="handleSkaybox($event)" [isShowStats]="showstatsFlag" [ARdata]="arData">

                </app-news3-d-popup>
            </div>

            <div class="garment-story-new" *ngIf="showGrmentStoryCard && mainMenuFlag"
                [ngClass]="this.funcValue == 'loaded' ? '' : 'd-none'">
                <div class="close-card-x"><i class="bi bi-x" (click)="closecard()"></i></div>
                <app-drips  #childrip
                    *ngIf="userSnapShotListGarment.length >= 0  && userSnapShotListGarment != undefined && userSnapShotListGarment.length >= 0"
                    (click)="closeNav()" [threadsPageFlage]="mainMenuFlag" [mainMenuFlag]="mainMenuFlag"
                    (brandSnapShottEmmit)="handlebrandSnapShot($event)" [userSnapShotsInput]="userSnapShotListGarment"
                    (dataEmitter)="handleData($event)" (branEmitter)="handlbrand($event)"
                    [thresholdHeightPagination]="thresholdHeightPagination" [StoryIds]="selectedStoryId"
                    [PageCount]="pagecount" [pageNumber]="pageNumber" (environmentDataEmitter)="handlevnData($event)"
                    (userDataEmitter)="handlUserData($event)" (garmentDataEmitter)="handlUsGarment($event)"
                    (outStoryIndeX)="storyIndex($event)" (avatrVrmValueEmitter)="handleAvataData($event)"
                    (avatrNewVrmValueEmitter)="newVrmAvatar($event)" (visibleIndex)="handleVisibleIndex($event)"
                    (emmitStoryIndex)="handleStoryCall($event)" (visibleIndex)="handlePreviousPage($event)"
                    (emmitWholeSearch)="handleStorysarch($event)" [nextPage]="nextPage"
                    [showBrandSlide_threads]="sameSliderBrand"
                    [showBrandAvatarslider]="showBrandAvatarslider" 
                    [selectSpaceTab]="selectSpaceTab"
                    [threadsPage_avatar_flag]="threadsPage_avatar_flag"

                    >
                </app-drips>
            </div>

            <div class="garment-story-new" *ngIf="showBrandStoryCard && mainMenuFlag">
                <div class="close-card-x"><i class="bi bi-x" (click)="closeBrandcard()"></i></div>
                <app-drips  #childrip (click)="closeNav()" [mainMenuFlag]="mainMenuFlag" [StoryIds]="selectedStoryId"
                    [userSnapShotsInput]="userSnapShotList" 
              (outStoryIndeX)="storyIndex($event)"
                    
                    (environmentDataEmitter)="handlevnData($event)"
                    (userDataEmitter)="handlUserData($event)"
                    (garmentDataEmitter)="handlUsGarment($event)"
                    (branEmitter)="handlbrand($event)" 
                    (dataEmitter)="handleData($event)"
                     (avatrVrmValueEmitter)="handleAvataData($event)"

                    (visibleIndex)="handleBrandVisibleIndex($event)" (emmitStoryIndex)="handleBrandStoryCall($event)"
                    (visibleIndex)="handleBrandPreviousPage($event)" [nextPage]="nextPage"
                    (emmitWholeSearch)="handleStorysarch($event)"
                    [showBrandSlide_threads]="sameSliderBrand"
                    [showBrandAvatarslider]="showBrandAvatarslider" 
                    [selectSpaceTab]="selectSpaceTab"

                    [threadsPage_avatar_flag]="threadsPage_avatar_flag"
                    >
                </app-drips>
            </div>

            <div class="garment-story-new" *ngIf="selectedSnapData">
                <div class="close-card-x"><i class="bi bi-x" (click)="closeSearched()"></i></div>
                <app-drips  #childrip (click)="closeNav()" 
                [mainMenuFlag]="mainMenuFlag" [StoryIds]="StoryId" [userSnapShotsInput]="searchFilterData.userSnafilter"
                 (emmitWholeSearch)="handleStorysarch($event)"   

                    (environmentDataEmitter)="handlevnData($event)"
                    (userDataEmitter)="handlUserData($event)"
                    (garmentDataEmitter)="handlUsGarment($event)"
                    (branEmitter)="handlbrand($event)" 
                    (dataEmitter)="handleData($event)"
                     (avatrVrmValueEmitter)="handleAvataData($event)"

                     [showBrandSlide_threads]="sameSliderBrand"
                     [showBrandAvatarslider]="showBrandAvatarslider" 
                     [selectSpaceTab]="selectSpaceTab"   
                     [threadsPage_avatar_flag]="threadsPage_avatar_flag"
                 >
                </app-drips>
            </div>

            <!-- <div class="brand-story-card" *ngIf="showBrandStoryCard && mainMenuFlag">

            </div> -->

        </div>
    </div>
</div>

<div class="brand-space-list" *ngIf="filteredEvenfileList && filteredEvenfileList.length > 0">
    <!-- Toggle Dropdown -->
    <div (click)="toggleDropdown()">
        {{ spaceName !== '' ? spaceName : 'Spaces' }}
    </div>

    <!-- Dropdown List -->
    <div *ngIf="isDropdownVisible" class="dropdown-list">
        <!-- Hardcoded "Spaces" option -->
        <div class="d-block">
            <span (click)="selectEven('Spaces')" *ngIf="spaceName !== 'Spaces'"  >Spaces</span>
        </div>

        <!-- Dropdown List with Dynamic Items -->
        <div class="d-block" *ngFor="let item of filteredEvenfileList">
            <span (click)="selectEven(item)" *ngIf="spaceName !== item.displayName" >
                {{ item.displayName }}
            </span>
        </div>
    </div>
</div>



<div class="main-avatar-slider m-1" [ngClass]="{ 'disabled': funcValue !== 'loaded' }">
    <div class="selected-avatar" (click)="showAvatarSlider()">
        <img *ngIf="tab == 'womens-wear'" [src]="defaultSelectedAvatarFeMale?.PortraitPreviewUrl"
            class="selected-avatar-image" />
        <img *ngIf="tab == 'mens-wear'" [src]="defaultSelectedAvatarMale?.PortraitPreviewUrl"
            class="selected-avatar-image" />
    </div>

    <div class="avatar-slider" *ngIf="tab == 'womens-wear' && showSlider">
        <div class="avatar-item p-1" *ngFor="let avatar of defaultFemaleAvatarsList"
            (click)="selectFemaleAvatar(avatar)">
            <img *ngIf="defaultSelectedAvatarFeMale?.PortraitPreviewUrl != avatar?.PortraitPreviewUrl"
                [src]="avatar?.PortraitPreviewUrl" class="avatar-image" />
        </div>
    </div>
    <div class="avatar-slider" *ngIf="tab == 'mens-wear' && showSlider">
        <div class="avatar-item p-1" *ngFor="let avatar of defaultMaleAvatarsList" (click)="selectMaleAvatar(avatar)">
            <img *ngIf="defaultSelectedAvatarMale?.PortraitPreviewUrl != avatar?.PortraitPreviewUrl"
                [src]="avatar?.PortraitPreviewUrl" class="avatar-image" />
        </div>
    </div>
</div>

<div *ngIf="funcValue == 'loaded' && dripsPageFlage" class="btn-resize-dwon d-flex">
    <div *ngIf="funcValue == 'loaded'" class="story-model-expand" (click)="switchFlagforFocus()">
        <span><button class="story " [ngClass]="{ 'active-new': isCheckedFocus === false}">Story</button></span>
        <span><button class="model" [ngClass]="{ 'active-new': isCheckedFocus === true}">Model</button></span>
    </div>
</div>


<label class="switch" *ngIf="(functionFlag == 'FocusOnStory' ) && funcValue === 'loaded'">
    <input type="checkbox" id="toggle-switch" [(ngModel)]="isChecked" (click)="toggleSwitch()">
    <span class="slider round">
        <span class="label on">Story</span>
        <!-- <span class="label off">Blank</span> -->
    </span>
</label>

<div *ngIf="funcValue == 'loaded'" (click)="ToggleAR()" class="AR-toggle-container p-2 m-2">
    <img class="AR-toggle-icon" src="./assets/cube.png" />
</div>