<div (click)="closePanel()">
    <app-yologram-social *ngIf="logInFlag"></app-yologram-social>
    <app-public *ngIf="!logInFlag"></app-public>
    <!-- <div (scroll)="scrollHandler()" #userFeeds class="roksan"  [ngStyle]="{ 'height.px': isMobile ?  windowHeight + 60 :  windowHeight - 60}">
        <div class="col-lg-12  page-scroll sticky-band" *ngIf="pageScroll > 30">
            <span class="d-flex justify-content-center ">
                <div class=" col-lg-12 band justify-content-center">
                    <div class="static-band " style="margin-left: 0px  !important;">
                        <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                            <img *ngIf="userProfileData?.ProfilePicture != undefined"
                                class="img-thumbnail rounded-circle" style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " src={{userProfileData?.ProfilePicture}}>
                            <img *ngIf="userProfileData?.ProfilePicture == undefined"
                                class="img-thumbnail rounded-circle" style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " src={{userProfileData?.ProfilePicture}}>
                        </div>
                        <div class="w-100 col-12 d-flex">
                            <div class="col-9 text-start">
                                <h5 *ngIf="userProfileData?.DisplayName == undefined" class="m-1 text-start text-black"
                                    (click)="getSnapShotListByUserID()">{{userData?.profileName}}</h5>
                                <h5 *ngIf="userProfileData?.DisplayName != undefined" class="m-1 text-black user-name"
                                    (click)="getSnapShotListByUserID()">
                                    {{userProfileData?.DisplayName}}
                                </h5>
                            </div>
                            <div class="col-3 "><button type="button" style="font-size: 10px;"
                                    class="btn btn-outline-info">Follow</button>
                            </div>
                        </div>
                        <div class="d-flex align-items-center avatar-sticky" *ngIf="newArray.length != 0">
                            <img class="avatar-image" style="width: 40px; height: 40px;"
                                [src]="userAvatarData.AvatarPreviewPortraitUrl">
                            <span class="avatar-name"><span
                                    class="avatar-details">{{userAvatarData.AvatarName}}</span></span>
                        </div>
                        <div>
                            <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                <div class="brandbtn">
                                    <h6 class="m-1" (click)="selectSummeryView('Story')"
                                        [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>

                                            <img *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                src="  ./assets/storystyle/story-white.png">
                                            <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                src=" ./assets/storystyle/storyblack.png">

                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                                </div>
                                <div class="brandbtn" (click)="selectTab('Brands')">
                                    <h6 class="m-1"
                                        [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>
                                            <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                                src="./assets/brand_white.png">
                                            <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                                src="./assets/brand_black.png">

                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                                </div>
                                <div class="brandbtn" (click)="selectTab('Style')">
                                    <h6 class="m-1 "
                                        [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>
                                            <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                                src="./assets/t_shirt_white.png">
                                            <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                                src="./assets/t_shirt_black.png">
                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        <div *ngIf="filterSnap" class="justify-content-center">
            <div class="col-lg-12 band">
                <div *ngIf="snaploader2D" class="text-center">
                    <div class="spinner-border m-2 p-2" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div class="static-band justify-content-center"
                    *ngIf=" pageScroll == undefined ||  pageScroll < 30    ">
                    <div class="d-flex m-1 profile-popup text-white " data-bs-dismiss="modal">
                        <img *ngIf="userProfileData?.ProfilePicture != undefined" class="img-thumbnail rounded-circle"
                            style="
                          width: 60px !important;
                          height: 60px !important;
                          margin-top: -53px;
                      " src={{userProfileData?.ProfilePicture}}>
                        <img *ngIf="userProfileData?.ProfilePicture == undefined" class="img-thumbnail rounded-circle"
                            style="
                          width: 60px !important;
                          height: 60px !important;
                          margin-top: -53px;
                      " src={{userProfileData?.photoURL}}>
                    </div>
                    <div class="w-100 col-12 d-flex">
                        <div class="col-9 text-start">
                            <h5 *ngIf="userProfileData?.DisplayName == undefined" class="m-1 text-start text-black"
                                (click)="getSnapShotListByUserID()">{{userData?.profileName}}</h5>
                            <h5 *ngIf="userProfileData?.DisplayName != undefined" class="m-1 text-black user-name"
                                (click)="getSnapShotListByUserID()">
                                {{userProfileData.DisplayName}}
                            </h5>
                        </div>
                        <div class="col-3 "><button type="button" style="font-size: 10px;"
                                class="btn btn-outline-info">Follow</button>
                        </div>
                    </div>
                    <div class="text-start" style="height: 25px; width: 100%; margin-bottom: 18px;
                    margin-left: 20px;">




                        <div class="d-flex align-items-center " *ngIf="newArray.length != 0">
                            <img class="avatar-image" style="width: 40px; height: 40px;"
                                [src]="userAvatarData.AvatarPreviewPortraitUrl">
                            <span class="avatar-name"><span
                                    class="avatar-details">{{userAvatarData.AvatarName}}</span></span>
                        </div>



                    </div>


                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                        <div class="brandbtn">
                            <h6 class="m-1   " (click)="selectSummeryView('Story')"
                                [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                        src="  ./assets/storystyle/story-white.png">
                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                        src=" ./assets/storystyle/storyblack.png">

                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                        </div>
                        <div class="brandbtn" (click)="selectTab('Brands')">
                            <h6 class="m-1 "
                                [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                        src="./assets/brand_white.png">
                                    <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                        src="./assets/brand_black.png">


                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                        </div>
                        <div class="brandbtn">
                            <h6 class="m-1  " (click)="selectTab('Style')"
                                [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                        src="./assets/t_shirt_white.png">
                                    <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                        src="./assets/t_shirt_black.png">
                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class ">Style</h6>
                        </div>
                    </div>
                </div>
                <div class="justify-content-center story-center d-flex">
                    <h6 class="m-2 text-primary  color-class">
                        <div *ngIf="!snaploader2D" class="row d-flex twoimages">
                            <div *ngIf="newArray.length != 0 "
                                class="text-center col-12 justify-content-center twoimages">
                                <app-slider-two-d [images]="newArray" [UserId]="userId"></app-slider-two-d>
                            </div>
                        </div>
                    </h6>
                </div>

               





                <div class="d-block text-white text-center justify-content-center">
                    <h6>Avatar : {{userAvatarData.AvatarName}} ({{userAvatarData.Gender =='MALE' ? 'M': 'F'}})</h6>
                    <h6>Size : {{userAvatarData.FatBlendshapeValue}}</h6>
                    <h6>Height Scale : {{userAvatarData.HeightScale}}</h6>





                </div>

                <div class="large-box">
                    <div class="col-12 col-sm-12  d-flex">
                        <div class="col-2 col-sm-2 m-1">
                            <img class="m-1" style="width:45px; height:45px !important; color:black;"
                                (click)="openBrandPanel(userAvatarData.cHeadgearID,$event)"
                                *ngIf="getGarmentrealtype(userAvatarData.cHeadgearID) == '0' "
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cHeadgearID)" />
                        </div>
                        <div class="col-8 col-sm-8 clothName p-1"
                            (click)="openGarmentNav(userAvatarData,'HeadwearID',userAvatarData.cHeadgearID,'headgear',$event)"
                            *ngIf="userAvatarData.cHeadgearID != undefined && userAvatarData.cHeadgearID!= ''">
                            <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cHeadgearID) == '0' "
                                style="width:15px !important; color:black ; opacity: 0.4;"
                                src="./assets/newsfeed/headgear-new.png">
                            <span class="caption-values"
                                *ngIf="userAvatarData.cHeadgearID != undefined && userAvatarData.cHeadgearID != ''">
                                <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                    title="Cloth Details">
                                    {{getGarmentName(userAvatarData.cHeadgearID).substring(0,25)}}
                                </span>&nbsp;
                            </span>
                          
                        </div>
                    </div>
                    <div class="col-12 col-sm-12  d-flex">
                        <div class="col-2 col-sm-2 m-1 ">
                            <img class="m-1" (click)="openBrandPanel(userAvatarData.cUpperwearID,$event)"
                                style="width:45px; height:45px !important; color:black ; "
                                *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '1' "
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cUpperwearID)" />
                            <img class="m-1" (click)="openBrandPanel(userAvatarData.cFullBodyID,$event)"
                                style="width:45px; height:45px !important; color:black ;"
                                *ngIf="getGarmentrealtype(userAvatarData.cFullBodyID) == '5' "
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cFullBodyID)" />
                        </div>
                        <div class="col-8 col-sm-8 clothName p-1"
                            *ngIf="userAvatarData.cUpperwearID != undefined &&userAvatarData.cUpperwearID != ''">
                            <span
                                (click)="openGarmentNav(userAvatarData,'UpperwearID',userAvatarData.cUpperwearID,'upperwear',$event)">
                                <img class="gar-icon"
                                    *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '1' "
                                    style="width:15px !important; color:black; opacity: 0.4;"
                                    src="./assets/realtype/upperwear.png">
                                <img class="gar-icon"
                                    *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '5' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="userAvatarData.cUpperwearID != undefined && userAvatarData.cUpperwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details">
                                        {{getGarmentName(userAvatarData.cUpperwearID).substring(0,25)}}
                                    </span>&nbsp;
                                </span>
                            </span>
                          
                        </div>
                        <div class="col-8 col-sm-8 clothName p-1"
                            *ngIf="userAvatarData.cFullBodyID != undefined &&userAvatarData.cFullBodyID != ''">
                            <span
                                (click)="openGarmentNav(userAvatarData,'UpperwearID',userAvatarData.cFullBodyID,'upperwear',$event)">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cFullBodyID) == '5' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="userAvatarData.cFullBodyID != undefined && userAvatarData.cFullBodyID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details">
                                        {{getGarmentName(userAvatarData.cFullBodyID).substring(0,25)}}
                                    </span>&nbsp;
                                </span>
                            </span>
                         
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 d-flex ">
                        <div class="col-2 col-sm-2  m-1">
                            <img (click)="openBrandPanel(userAvatarData.cBottomwearID,$event)"
                                style="width:45px; height:45px !important; color:black ; "
                                *ngIf="getGarmentrealtype(userAvatarData.cBottomwearID) == '2'"
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cBottomwearID)" />

                        </div>
                        <div class="col-8 col-sm-8 clothName p-1"
                            *ngIf="userAvatarData.cBottomwearID != undefined && userAvatarData.cBottomwearID != ''">
                            <span
                                (click)="openGarmentNav(userAvatarData,'BottomwearID',userAvatarData.cBottomwearID,'bottomwear',$event)">


                                <img class="gar-icon"
                                    *ngIf="getGarmentrealtype(userAvatarData.cBottomwearID) == '2'"
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/newsfeed/bottomwear-new.png">
                                <span class="caption-values"
                                    *ngIf="userAvatarData.cBottomwearID != undefined &&userAvatarData.cBottomwearID!= ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details">{{getGarmentName(userAvatarData.cBottomwearID).substring(0,20)}}
                                    </span>&nbsp;
                                </span>
                            </span>

                           
                        </div>
                    </div>
                    <div class="col-12 col-sm-12  d-flex">
                        <div class="col-2 col-sm-2 ">
                            <img class="m-2" (click)="openBrandPanel(userAvatarData.cFootwearID,$event)"
                                style="width:45px; height:45px !important; color:black ;"
                                *ngIf="getGarmentrealtype(userAvatarData.cFootwearID) == '3'"
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cFootwearID)" />
                        </div>

                        <div class="col-8 col-sm-8 clothName p-1"
                            *ngIf="userAvatarData.cFootwearID != undefined && userAvatarData.cFootwearID != ''">
                            <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cFootwearID) == '3' "
                                (click)="openGarmentNav(userAvatarData,'FootwearID',userAvatarData.cFootwearID,'footwear',$event)"
                                style="width:15px !important; color:black ; opacity: 0.4;"
                                src="./assets/realtype/footwear.png">

                            <span class="caption-values"
                                (click)="openGarmentNav(userAvatarData,'FootwearID',userAvatarData.cFootwearID,'footwear',$event)"
                                *ngIf="userAvatarData.cFootwearID != undefined && userAvatarData.cFootwearID != ''">
                                <span class="garment-name" data-toggle="popover" data-placement="left"
                                    title="Cloth Details">
                                    {{getGarmentName(userAvatarData.cFootwearID).substring(0,25)}}
                                </span>&nbsp;
                            </span>



                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="snaploader" class="text-center">
                <div class="spinner-border m-5 p-5" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab  == 'user-story'" class="story-slass">
            <div class="drips-container">
                <app-drips (emmitStoryIndex)="handleStoryCall($event)" (visibleIndex)="handlePreviousPage($event)"
                    [mainMenuFlag]="mainMenuFlag" [showpanel]="showpanel" [userSnapShotsInput]="filterSnap"
                    [StoryIds]="selectedStoryId" [UserId]="userId" [AvatrId]="avatarID" [PageCount]="pagecount"
                    [pageNumber]="pageNumber" [nextPage]="nextPage">
                </app-drips>
            </div>
        </div>
        <div *ngIf="selectedTab  == 'story'" class="row justify-content-center mb-5  ">
            <div class="col-12 div-center">
                <h6 *ngIf="pageiIndex" (click)="previousbutton()"><b *ngIf="callpageNumer > 0 ">Previous</b></h6>
                <div class="row w-100">
                    <div class="col-4 p-0" *ngFor="let snapshot of filterSnap; let i=index;">
                        <div *ngIf="snapshot.SnapshotFileUrl != ''"
                            class="d-flex align-items-center justify-content-center m-1">
                            <img class="w-100 shadow-1-strong rounded m-1" (click)="scrollToStory(snapshot)"
                                [src]="snapshot.SnapshotFileUrl">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="filterSnap.length >= 12">
                <div class="col-12 text-center justify-content-center morebtn"
                    [ngClass]="{'hide': this.avatarID == undefined }">
                    <h6 (click)="nextButtonForAvatar(counter)"><b>More</b></h6>
                </div>
            </div>
        </div>
    </div> -->



    <!-- 
<div class="brand-close" id="closePannel" *ngIf="brandCollapseFlag" (click)=" clickOnPanel($event)">
    <div id="mySidenav" class="sidenav" *ngIf="closeDiv && !wholeDiv">
        <div *ngIf="brandCollapseFlag">
            <app-drips-brand-panel [newselectedBrandId]="selectedBrandId" [brandData]="brandData"
                [newbrandUrl]="newbrandUrl"></app-drips-brand-panel>
        </div>
    </div>
</div> -->

    <!-- 

<div class="brand-close" id="closePannel" *ngIf="germentCollapseFlag" (click)=" clickOnPanel($event)">
    <div id="mySidenav" class="sidenav" *ngIf="closeDiv && !wholeDiv">
        <div *ngIf="germentCollapseFlag">
            <app-drips-garment-panel [garmentId]="selecteGarmentId" [typeOfGarment]="typeOfGarment"
                [selectedSnapShot]="selectedGarmentName" [brandUrl]="brandUrl" [RealType]="realType"
                [garmentData]="garmentData" [typeWear]="garmentTypeID">
            </app-drips-garment-panel>
        </div>
    </div>
</div> -->




<button class="btn btn-primary" style="     z-index: 100000;
position: fixed;
top: 215px;
right: 0;" (click)="profilerCheck()"     >
    <i class="bi bi-arrow-right-circle" *ngIf="isStatsVisible"></i>
    <i class="bi bi-arrow-left-circle" *ngIf="!isStatsVisible"></i>
</button>
    <span class="d-block" *ngIf="this.selectedTab == ''" style="margin-left: 43px;">
        <img (click)="closeThreeDBUTTON()" class="dummy-card" src="./assets/small-card.png" />
        <img (click)="closeThreeDBUTTON()" class="short-image-card" [src]="findSnap?.SnapshotFileUrlMini || findSnap?.SnapshotFileUrl" />
        <img class="back-card" (click)="closeThreeDBUTTON()" style="width:20px !important; transform: scaleX(-1);"
            src="./assets/share_copy.png">
        <img (click)="handleAnimationList()" class="animation-icon" src="./assets/animation-icon-white.png" />
    </span>

    <div style="margin-left: 25px;" class="text-center" style="flex-grow: 1; z-index: 0;"
        *ngIf="animationListShow && this.selectedTab == ''">
        <button (click)="handleAnimationList()" class="animation-list-remove">
            X
        </button>
        <ul class="animation-list">
            <li *ngFor="let animation of animationCollection" (click)="onSelectAnimation(animation.storageURL)"
                class="animation-item">
                {{ animation.displayName }}
            </li>
        </ul>
    </div>

    <div *ngIf="this.selectedTab == '' && this.saveAvatarData?.dataBlobUrl" class="btn-resize-dwon d-flex">
        <div class="story-model-expand" (click)="switchFlagforFocus()">
            <span><button class="story" [ngClass]="{ 'active': isCheckedFocus === true}">Story</button></span>
            <span><button class="model" [ngClass]="{ 'active': isCheckedFocus === false}">Model</button></span>
        </div>
    </div>

    <div style="height: 300px;width:260px; background-color: black;">
        <app-news3-d-templete [GarmentsGender]="genders" [EnvUrl]="defaultEnvironment"
            [upperwearUrl]="UpperWearfinalUrl" [bottomwearUrl]="garmentbottom" [AvatarUrl]=" this.saveAvatarData?.avatraUrls  || this.avtarUrl"
            [headgearUrl]="headgear" [footwearUrl]="footwear" (calledVlueIntilized)="calledVlueIntilized($event)"
            [flag_v2]="flag_v2" [EnvUrl]="this.saveAvatarData?.envUrl || defaultEnvironment"
            [FunctionFlag]="this.saveAvatarData?.functionflagValue || functionflagValue" [upperwearUrl]="this.saveAvatarData?.garmenturl"
            [bottomwearUrl]="this.saveAvatarData?.garmentbottom" [BlobUrl]="this.saveAvatarData?.dataBlobUrl"
            [headgearUrl]="this.saveAvatarData?.headgear" [footwearUrl]="this.saveAvatarData?.footwear"
            [StoryID]="this.saveAvatarData?.storyID" [bvhAnimationUrl]="this.selectedAnimation"
            [BlobUrl]="this.saveAvatarData?.dataBlobUrl" [storyText]="storyText" [isShowStats]="isStatsVisible"
            
            
            >
        </app-news3-d-templete>
    </div>
</div>



<div class="brand-close" id="closePannel" *ngIf="avatarpanel" (click)="clickOnPanel($event)">
    <div id="mySidenav-avatar" class="sidenav"
        [ngClass]="closeDiv && !wholeDiv  ? 'expand-size' : 'actual-size'">
        <div>
            <div *ngIf="!closeDiv && wholeDiv" class="d-block">
               
                <!-- <div *ngIf="!showAvatarPanel && this.PageType == 'Avatar'" class="menu-arrow text-end tab-box"
                    (click)="openAvatarPanel('avatar')">
                    <img style="width:20px;" src="./assets/search/avatar.png" alt="Open Avatar">
                </div> -->
                <!-- <div *ngIf="!showUserPanel && this.PageType == 'User'" class="menu-arrow text-end tab-box"
                    (click)="openAvatarPanel('user')">
                    <img style="width:20px;" src="./assets/search/user.png" alt="Open Avatar">
                </div> -->

                <div class="menu-arrow text-end tab-box" (click)="openAvatarPanel('search')">
                    <img style="width:20px;" (click)="openAvatarPanel('search')" src="./assets/brand-space/search-button.png" alt="Open Search">
                </div>
            </div>

            <app-user-panel *ngIf="intiateuserpanel"  [ngClass]="showUserPanel && closeDiv ? '' : 'd-none'" 
                [UserID]="this.userId" [brandStoryScrollerHeight]="sideNavHeight" [userData]="userData" [updatdeIndex]="updatdeIndex"
                [selectedUserName]="userProfileData?.DisplayName" (emitSlectedSnapList)="handleSlectedSnapList($event)"
                [TabSelection]="PageTabSelection" [SetBrandId]="SetBrandId" [SetGarmentId]="SetGarmentId"
                [SetAvatarId]="SetAvatarId" [usrPageAvatarFlag]="usrPageAvatarFlag" 
                (sendStoryId)="handleStoryOnPaneluser($event)" (emitSlectedUSER_And_Avatar)="handUserIDAndAvatrID($event)" 
                [Index]="this.index" [scrollSide]="scrollSide" [callDripUserPanel]="callDripUserPanel"
                (UserDetails_Data)="handleUserDetails_Data($event)"
                [callAvatrUserPanel]="callAvatrUserPanel"
                (pageid)="handlecloPanel($event)" 
                (userDetailsObjectemit)="handleuserphotoPanel($event)" 
                (setTabSelection)="handlesetTabSelection($event)"
                (setTabSelectionBrandId)="handlesetTabBrandIdSelection($event)"
                (setTabSelectionGarment)="handlesetTabGarmentSelection($event)"
                [otherUserID]="otherUserID" [newIndex]="newIndex"
                
                > 
            </app-user-panel>

            <!-- Avatar Panel -->

            <app-avatar-panel *ngIf="intiateavatarpanel"  [ngClass]="showAvatarPanel && closeDiv ? '' : 'd-none'" 
                [UserShapShotObject]="snapshot" [UserID]="userId" [AvatarID]="avatarID"
                [selectedUserName]="this.tempAvatarPanelData?.selectedUserName || userProfileData?.DisplayName"
                [selectedAvatarName]="this.tempAvatarPanelData?.selectedAvatarName || avatarname" 
                [brandStoryScrollerHeight]="brandStoryScrollerHeight" 
                [TabSelection]="PageTabSelection" [SetBrandId]="SetBrandId"
                (emitSlectedSnapList)="handleSlectedSnapList($event)"
                 (sendStoryId)="handleStoryOnPanel($event)"
                [SetGarmentId]="SetGarmentId"  [Index]="this.index" [updatdeIndex]="updatdeIndex"[scrollSide]="scrollSide"
                (potrateImage)="handlePotrateImage($event)"
                (calloutputResult)="handleloadedData($event)" [tempAvatrData]="this.tempAvatarPanelData" [ResetPanel]="ResetPanel"
                (closeAvatarPanel)="handleCloseAvatrPanel($event)" (emitSlectedUSER_And_Avatar)="handUserIDAndAvatrID($event)" 
                [passTabSelection]="passTabSelection"
                
                [passTabSelectionStyle]="passTabSelectionStyle"
                (setTabSelection)="handlesetTabSelection($event)"
                (setTabSelectionBrandId)="handlesetTabBrandIdSelection($event)"
                (setTabSelectionGarment)="handlesetTabGarmentSelection($event)"

                (emitOpneChangeAvatar)="handlechangeAvatarButton($event)"
                (newEmitUserData)="handleNewAvatrUserData($event)"

                
                (avatrTabselection)="handleAvatarTab($event)"
                (show_brandslider_avatarEmit)="handleCross_BrandTag($event)"
                (show_clothlider_avatarEmit)="handleCross_GarmentTag($event)"

                [showCloth_avatar_Slider]="showCloth_avatar_Slider"
                [showBrand_avatar_Slider]="showBrand_avatar_Slider"
                
                [selectedCloth_AvatarPanel]="selectedCloth_AvatarPanel"
                [selectedBrand_AvatarPanel]="selectedBrand_AvatarPanel"

                >
            </app-avatar-panel>

            <!-- Search Panel -->
        </div>
    </div>
</div>




<!-- <div *ngIf="!storycardhidePnel" class="text-center">
    <div class="spinner-border m-2 p-2" role="status">
        <span class="sr-only"></span>
    </div>
</div> -->

<div (click)="closePanel()" [ngClass]="{'d-none': !showCrd}" class="story-class">
    <div class="drips-container"  >
        <div (click)="closecard()"   class="text-black text-end class-close-btn">X</div> 
           <app-drips #childrip (emmitStoryIndex)="handleStoryCall($event)" (visibleIndex)="handlePreviousPage($event)"
            (onavatrfeedEmitStoryID)="handleStoryId($event)" (avatrVrmValueEmitter)="handleAvatarEmitData($event)"
            [mainMenuFlag]="mainMenuFlag" [showpanel]="showpanel" [userSnapShotsInput]="filterSnap" 
            [StoryIds]="temp_StoryId" [UserId]="userId" [AvatrId]="avatarID" [PageCount]="pagecount"
            [pageNumber]="pageNumber" [nextPage]="nextPage" [avatarPage]="avatarPage"
            (emitAvatarPanel)="handleAvatarPnel($event)" [showSearchPanel]="showSearchPanel"
            (emitCloseSearch)="handleClosepanleFlag($event)" (openSearchPanelEmitFlag)="handleOpenpanleFlag($event)"
            (storyHasBeenSelected)="handleStorySelectedIncard($event)"
            (dataLoadedInDom)="handleDomView($event)" (emitScrollingSide)="handleScrollSide($event)"
            [storyCardflag]="hideStoryCard" [hideStoryCard]="hideStoryCard" [findSnap]="findSnap" (homeData)="handlehomeData($event)"
            (closeSearchPanelEmit)="handlecloseSearch($event)"
            (storyNEWHasBeenSelected)="handlestoryselctionFlag($event)"
            [storyLoader]="storyLoader" [search_Temp_Flag]="search_Temp_Flag" (emitUserpanleOpen)="handleUserPnl($event)"
            (newurl_space)="handlenew_even($event)" 

             (emitAllSnapShots)="handleAllstory($event)"

             (emitStorySelectBySearchpanel)="handleSetsearch($event)"


            [envPanle]="envpanel"

            [crossButton]="crossButton"

            (emitSnap)="handleMinyCard($event)"
            [threadsPage_avatar_flag]="threadsPage_avatar_flag"

            (emitSelectedBrand)="handleSelectedBrand($event)"

            (emitSelectedGarment)="handleSelectedGarment($event)"

            (showCloth_avatar_Slider_emit)="handleGarmentSlider($event)"
            (showBrand_avatar_Slider_emit)="handleBrandSlider($event)"

            >
        </app-drips>
    </div>
</div>


<div *ngIf="!closeDiv && wholeDiv" class="d-block preview-space">
    <div class="menu-arrow text-end tab-box space-box" >
        <div class="image-space" *ngIf="PageType =='Avatar' " (click)="openAvatarPanel('avatar')">
            <img class="small-image" src="./assets/search/avatar.png" alt="Open Avatar">
            <img class="large-image" [src]="this.showAvatarPotraite" alt="Open Avatar">
        </div>
        <div class="image-space" *ngIf="PageType =='User' " (click)="openAvatarPanel('user')" >
            <img class="small-image" src="./assets/search/user.png" alt="Open Avatar">
            <img class="large-image" *ngIf="!this.UserIamge " src="" alt="Open user">
            <img class="large-image" *ngIf="this.UserIamge "  [src]="this.UserIamge" alt="Open user">
        </div>
    </div>
</div>